import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getTodoListAction, getTodoListErrorAction, getTodoListSuccessAction } from './todo-list.actions';
import { TodoListService } from './todo-list.service';

@Injectable()
export class TodoListEffect {
  private readonly actions$ = inject(Actions);
  private readonly todoListService = inject(TodoListService);

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTodoListAction),
      switchMap(() =>
        this.todoListService.getTodoList().pipe(
          map((todoListResponse) => getTodoListSuccessAction({ todoListResponse })),
          catchError((errors) => of(getTodoListErrorAction(errors)))
        )
      )
    )
  );
}
