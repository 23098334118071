<div class="idenfy-status-wrapper">
  <ng-container [ngSwitch]="true">
    <div class="idenfy-status-content" *ngSwitchCase="status === 'APPROVED'">
      <div class="wizard-step__header">
        <h1 class="bp-heading">Success</h1>
      </div>
      <div class="idenfy-status-icon idenfy-status-icon__success">OK</div>
      <div class="idenfy-status-description">Your ID verification has been successful.</div>
      <button class="confirmo-button confirmo-button__blue idenfy-status-button" (click)="nextStep()">
        Continue
      </button>
    </div>
    <div class="idenfy-status-content" *ngSwitchCase="status === 'DENIED' || status === 'SUSPECTED'">
      <div class="wizard-step__header">
        <h1 class="bp-heading">Refused</h1>
      </div>
      <div class="idenfy-status-icon idenfy-status-icon__refused"></div>
      <div class="idenfy-status-description">Your ID verification has not been successful.</div>
      <div *ngIf="status === 'DENIED' || status === 'SUSPECTED'" class="idenfy-rejected idenfy-rejected__text">
        <p class="idenfy-rejected__paragraph">
          Are you using a correct document
          <span
            matTooltip="ID cards are accepted only. Other documents such as passport, driver's license, etc. are not accepted."
          >
            (?)
          </span>
        </p>
        <p class="idenfy-rejected__paragraph">
          Do you have sufficient lighting for the verification
          <span
            matTooltip="When processing verification in poor lighting conditions or in a backlight (a lamp or a window behind your back), the information on the document may not be readable."
          >
            (?)
          </span>
        </p>
        <p class="idenfy-rejected__paragraph">
          Do you use a camera with sufficient resolution
          <span
            matTooltip="Built-in camera resolution in PCs or older smartphones may be too low. If verification fails on PC, please use a mobile phone instead."
          >
            (?)
          </span>
        </p>
      </div>
      <button class="confirmo-button confirmo-button__blue idenfy-status-button" (click)="emitBackToOffer()">
        Try again
      </button>
    </div>
    <div class="idenfy-status-content" *ngSwitchCase="status === 'REVIEWING'">
      <div class="wizard-step__header">
        <h1 class="bp-heading">Verifying</h1>
      </div>
      <mat-icon class="idenfy-status-icon idenfy-status-icon__pending">timelapse</mat-icon>
      <div class="idenfy-status-description">
        Your ID is being verified, please allow up to 24 hours for the final result.
      </div>
    </div>
    <div class="idenfy-status-content" *ngSwitchDefault>
      <!-- included EXPIRED -->
      <div class="wizard-step__header">
        <h1 class="bp-heading">ID Verification</h1>
      </div>
      <div class="idenfy-status-icon idenfy-status-icon__error">!</div>
      <div class="idenfy-status-description"
        >Your iDenfy verification could not be completed. Use the button below to try again.</div
      >
      <button class="confirmo-button confirmo-button__blue idenfy-status-button" (click)="emitBackToOffer()">
        Verify account
      </button>
    </div>
  </ng-container>
</div>
