import { TodoItemModel } from '../../models/api/todo-item.model';
import { StateModel } from '../../models/auxiliary/state.model';
import { getTodoListAction, getTodoListErrorAction, getTodoListSuccessAction } from './todo-list.actions';
import { createReducer, on } from '@ngrx/store';

export type TodoState = StateModel<TodoItemModel[]>;

const INITIAL_STATE: TodoState = {
  errors: null,
  data: null,
  initial: true,
  loading: true,
};

export const todoReducer = createReducer(
  INITIAL_STATE,
  on(getTodoListAction, (state) => ({
    ...INITIAL_STATE,
    loading: true,
  })),
  on(getTodoListSuccessAction, (state, { todoListResponse }) => ({
    errors: null,
    data: todoListResponse,
    loading: false,
  })),
  on(getTodoListErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  }))
);
