/* tslint:disable:max-classes-per-file - Disable TSlint rule for actions */

import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/api/error.model';

export enum TodoActionTypes {
  GET_REQUEST = 'todo/GET_REQUEST',
  GET_SUCCESS = 'todo/GET_SUCCESS',
  GET_ERROR = 'todo/GET_ERROR',
}

export const getTodoListAction = createAction(TodoActionTypes.GET_REQUEST);

export const getTodoListSuccessAction = createAction(TodoActionTypes.GET_SUCCESS, props<{ todoListResponse: any }>());

export const getTodoListErrorAction = createAction(TodoActionTypes.GET_ERROR, props<{ errors: ErrorModel[] }>());
